<template lang="html">
   <!-- <div class="layout--main" >
    <template >
      <the-navbar-vertical-perfil/>
    </template>
   

      <div class="">

        <div class="router-view">
          <div class="router-content">

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

               
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

               
                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>

              </div>-->

<div class="vista-inmobiliaria">
              
  <div class="" v-if="mostrarFavoritos" >
      <div class=" "  >
        <h1 class="ocultar favoritos-resumen">
          <svg class="heart">
            <path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)"/>
          </svg>Favoritos</h1>
         <div class="">
            <div class="excel"v-if="$store.state.AppActiveUser.TipoEjecutivoV3!=='Inmo' && nuevaCotizacion==true">
              <button  class="btn btn-exportar mt-4" style="padding:0px" @click="regresarAcotizacion()" >
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.334 11.675L12.148 14.861L15.334 18.047" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.892 14.861L12.234 14.861" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path opacity="0.4" d="M14.9349 24.892C9.43494 24.892 4.97794 21.158 4.97794 14.935C4.97794 8.71201 9.43493 4.97801 14.9349 4.97801" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg><strong>Regresar</strong>
              </button>
           </div>
           </div>
      </div>     
            <div class="">
            
              <div class="card-resumen">
                <div class="resumen-titulo">Resumen de crédito</div>
                <div class="resumen-valor">
                    <div class=" ">Valor Inmueble: <span class="monto-valor">$ {{caso.ValorInmueble}}</span></div>
                </div>
                <div v-if="caso.Destino == 1 || caso.Destino == 24 || caso.Destino == 21 || caso.Destino == 3 || caso.Destino == 27 || caso.Destino == 15 || caso.Destino == 8 || caso.Destino == 10 || caso.Destino == 32 || caso.Destino == 5 || caso.Destino == 20 || caso.Destino == 2">
                  <div class="resumen-monto">
                      <div class="  ">Monto de crédito: <span class="monto-valor">$ {{caso.MontoSolicitado}}</span></div>
                  </div>    
                </div> 
                
                <div v-if="caso.Destino == 19 || caso.Destino == 25 || caso.Destino == 22 || caso.Destino == 33 || caso.Destino == 34">
                      <div class="resumen-monto">
                          <div class="  ">Saldo Insoluto: <span class="monto-valor">$ {{caso.SaldoInsoluto}}</span></div>
                      </div>
                      </br>
                      <div class="resumen-monto">
                          <div class="  ">Pago mensual actual: <span class="monto-valor">$ {{caso.PagoMensualActual}}</span></div>
                      </div>
                      </br>
                      <div class="resumen-monto">
                          <div class="  ">Plazo restante: <span class="monto-valor"> {{caso.PlazoRestante}}</span></div>
                      </div>

                </div>


                    <div id="botones" class="col-md-12 d-md-flex justify-content-md-end">
                      <button style="background: #4AA84F !important; color:#FFF; margin-right:20px;" class="btn "  icon-pack="feather" icon="icon-printer"  @click="print" v-if="$store.state.AppActiveUser.TipoEjecutivoV3!=='Inmo' && nuevaCotizacion==false"  >Imprimir</button>
                      <button style="background: #4AA84F !important; color:#FFF; margin-right:20px;" class="btn "  icon-pack="feather" icon="icon-printer"  @click="print" v-if="$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo' "  >Imprimir</button>
                      <button style="background: #4AA84F !important; color:#FFF; margin-right:20px;" class="btn " icon-pack="feather" icon="icon-mail"   @click="sendEmail" v-if="$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo' " >Enviar por correo</button>
                      <button style="background: #4AA84F !important; color:#FFF; margin-right:20px;" class="btn " icon-pack="feather" icon="icon-mail"   @click="sendEmail" v-if="$store.state.AppActiveUser.TipoEjecutivoV3!=='Inmo' && nuevaCotizacion==false" >Enviar por correo</button>
                    </div>
                  </div>  
            </div>
          

      <br>
      <br>

      <vs-table :data="favoritos" class="tabla-resumen">
        <template slot="thead">
          <vs-th sort-key="Banco">Banco</vs-th>
          <vs-th sort-key="Mensualidad">Mensualidad</vs-th>
          <vs-th sort-key="Plazo">Plazo</vs-th>
          <vs-th sort-key="PagoTotal">Pago Total</vs-th>
          <vs-th sort-key="Tasa">Tasa</vs-th>
          <vs-th sort-key="Cat">CAT</vs-th>
          <vs-th sort-key="IngresoMinimo">Ingresos Requeridos</vs-th>
          <vs-th sort-key="GastosIniciales">Gastos Iniciales</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].Logo">
              <div class="bankName">
                {{ data[indextr].Banco }}
              </div>
              <p style="font-size:10px;color:black">{{ data[indextr].Producto }}</p>
              <img :src="'https://cotizador.socasesores.com/uploads/'+ data[indextr].Logo" :alt="data[indextr].Banco" class="logo">
            </vs-td>
            <vs-td :data="data[indextr].Mensualidad">
            <!-- ${{ data[indextr].Mensualidad }}<feather-icon icon="CheckIcon" v-if="data[indextr].MejorMensualidad"/>-->
            <span style="font-weight:600;" v-if="data[indextr].MejorMensualidad">${{ data[indextr].Mensualidad }}</span>
            <span style="" v-else="!data[indextr].MejorMensualidad">${{ data[indextr].Mensualidad }}</span>
            </vs-td>
            <vs-td :data="data[indextr].Plazo">
              {{ data[indextr].Plazo/ 12 }} Años
            </vs-td>
            <vs-td :data="data[indextr].PagoTotal">
            <!-- ${{ data[indextr].PagoTotal }}<feather-icon icon="CheckIcon" v-if="data[indextr].MejorPagoTotal"/>-->
              <span style="font-weight:600;" v-if="data[indextr].MejorPagoTotal">${{ data[indextr].PagoTotal }}</span>
              <span style="" v-else="!data[indextr].MejorPagoTotal">${{ data[indextr].PagoTotal }}</span>
            </vs-td>
            <vs-td :data="data[indextr].Tasa">
              <!--{{ data[indextr].Tasa }}%<feather-icon icon="CheckIcon" v-if="data[indextr].MejorTasa"/>-->
              <span style="font-weight:600;" v-if="data[indextr].MejorTasa">{{ data[indextr].Tasa }}%</span>
              <span style="" v-else="!data[indextr].MejorTasa">{{ data[indextr].Tasa }}%</span>
            </vs-td>
            <vs-td :data="data[indextr].Cat">
              <!--{{ data[indextr].Cat }}%<feather-icon icon="CheckIcon" v-if="data[indextr].MejorCat"/>-->
              <span style="font-weight:600;" v-if="data[indextr].MejorCat">{{ data[indextr].Cat }}%</span>   
              <span style="" v-else="!data[indextr].MejorCat">{{ data[indextr].Cat }}%</span>       
            </vs-td>
            <vs-td :data="data[indextr].IngresoMinimo">
              <!--${{ data[indextr].IngresoMinimo }}<feather-icon icon="CheckIcon" v-if="data[indextr].MejorIngresoMinimo"/>-->
              <span style="font-weight:600;" v-if="data[indextr].MejorIngresoMinimo">${{ data[indextr].IngresoMinimo }}</span> 
              <span style="" v-else="!data[indextr].MejorIngresoMinimo">${{ data[indextr].IngresoMinimo }}</span>         
            </vs-td>
            <vs-td :data="data[indextr].GastosIniciales">
              <!--${{ data[indextr].GastosIniciales }}<feather-icon icon="CheckIcon" v-if="data[indextr].MejorGastosIniciales"/>-->
              <span style="font-weight:600;" v-if="data[indextr].MejorGastosIniciales">${{ data[indextr].GastosIniciales }}</span>
              <span style="" v-else="!data[indextr].MejorGastosIniciales">${{ data[indextr].GastosIniciales }}</span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
                <p>*Recuerda que estos resultados se calculan con base a los valores que nos indicas y datos que son proporcionados por la entidad financiera. Todos los datos están sujetos a validación y su correcta aprobación</p>
  </div>
 
  <div v-if="mostrarInfoSolicitante">
    <vx-card title=""  >
      <h4 class="titulo">Opciones de crédito</h4>
    <div class="row w-full">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn btn-primary" @click="regresar()">Regresar</button>
        <button class="btn btn-secondary"  @click="registrarSolicitante()" :disabled="!validar">Terminar</button>
      </div>
    </div>    
    <div class="row w-full">
      <div class="col-md-3">
        <label class="span-placeholder">Nombre</label>
        <input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="nombre" name="nombres" />
       <!-- <span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido, solo caracteres</span>-->
      </div> 
      <div class="col-md-3">
        <label class="span-placeholder">Segundo nombre</label>
        <input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="segundoNombre" name="segundoNombre" />
        <!--<span class="text-danger text-sm" v-show="errors.has('segundoNombre')">Campo requerido, solo caracteres</span>-->
      </div> 
      <div class="col-md-3">
        <label class="span-placeholder"> Apellido Paterno</label>
        <input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="apellidoPaterno" name="apellidoPaterno" />
        <!--<span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido, solo caracteres</span>   -->     
      </div> 
      <div class="col-md-3">
        <label class="span-placeholder"> Apellido Materno</label>
        <input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="apellidoMaterno" name="apellidoMaterno" />
        <!--<span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido, solo caracteres</span>-->        
      </div>    
    </div>
    <div class="row w-full">
      <div class="col-md-3">
        <label class="span-placeholder">Celular (10 dígitos)</label>
        <input class="vs-input" v-model="telefono" name="telefono" maxlength="10" v-validate="'numeric|required|min:10|max:10'" v-on:keypress="isNumber($event)"/>
        <!--<span class="text-danger text-sm" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span> -->         
      </div> 
       <div class="col-md-3">
        <label class="span-placeholder">Correo electrónico</label>
        <input class="vs-input"  v-model="email" v-validate="'required|email'" name="email" />
        <!--<span class="text-danger text-sm" v-show="errors.has('email')">Campo requerido, debe ser un email válido</span>     -->  
      </div>
    </div>
    </vx-card>
  </div>
  <div  class="modal fade " id="modalTabla"  ref="table" size="sm" hide-footer hide-header >
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="d-md-flex justify-content-md-center"><h1 style="color: #00368C;">{{mensajeModal}}</h1></div>
              <div class="d-md-flex justify-content-md-center" id="icono"></div>
              <!--<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                <path  d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>-->
            </div>
            <div class="row">
              <div class="d-md-flex justify-content-md-center"><h1 style="color: #00368C;">Folio de simulación</h1></div>
            </div>
            <div class="row">
              <div class="d-md-flex justify-content-md-center"><h1 style="color: #00368C;">{{id}}</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>      
</div>
</template>

<script>
import TheNavbarVerticalInmobiliaria   from '@/layouts/components/navbar/TheNavbarVerticalInmobiliaria.vue'

import axios from 'axios';
export default {
  components:{
    TheNavbarVerticalInmobiliaria
  },
  data(){
		return{
      caso:{},
      favoritos:[],
      id:'',
      mostrarInfoSolicitante:false,
      mostrarFavoritos:true,
      nombre:'',
      segundoNombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      email:'',
      emailEjecutivo:'',
      mensajeModal:'Correo enviado',
      nuevaCotizacion:false,
      icono:''

    }
  },
  mounted(){
    if(localStorage.getItem('vistaNuevaCotizacion')){
      this.nuevaCotizacion=true
    }
    this.id=this.$route.params.id
    this.getApikey()
    this.dameCaso()
  },
  computed:{
    socData(){
      //return this.$store.state.socData
      return JSON.parse(localStorage.getItem('socData'))
    },
    emailState() {
      const regex = /^[-\w.%+]{1,64}@(?!socasesores)(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.email);
      return this.email == null || matches ? true:false
    },
    celphoneState() {
      return this.telefono == null || this.telefono.length == 10 ? true : false
    },
    nameState() {
      return   this.nombre==null || this.nombre.length >= 3 ? true : false
    },
    lastNameState() {
      return this.apellidoPaterno == null || this.apellidoPaterno.length > 2 ? true : false
    },
     /* emailState() {
      const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.correo);
      return this.correo == null || matches ? true:false
    },*/
       validar(){
           return this.emailState && this.lastNameState && this.nameState && this.celphoneState
       }
  },
  methods:{
    regresarAcotizacion(){
      this.$router.push('/nuevaCotizacion');
    }, 
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editaCaso(){
      //let self= this
      var objRequestRegistraCaso1 = {
        strApiKey: this.key,//'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'RegistraCaso',
        objCaso: {
          Id: this.id,
          Solicitante: this.email,
          Destino : this.caso.destino,
          Broker : this.emailEjecutivo,
        }
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            //alert('registrado')
            this.sendEmail()
            localStorage.removeItem('caso')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en registraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameInformacion(){
      let self=this
      let objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'MisDatos',
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let data= response.data.objContenido
            this.emailEjecutivo=data.EMail
            this.editaCaso()
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registrarSolicitante(){
      let self=this
      /*this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })*/
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
      	  Nombres: this.nombre,
          SegundoNombre: this.segundoNombre,
      	  ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
      	  TelefonoCelular: this.telefono,
      	  FechaNacimiento: '1992-01-01',
          StateData: 0,
        }
      }
      
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.dameInformacion()
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        
    },
    regresar(){
      localStorage.setItem('regreso', true)
      this.mostrarInfoSolicitante=false
      this.mostrarFavoritos=true
      this.$router.push('/nuevaCotizacion');
    },
    mostrarForm(){
      this.mostrarInfoSolicitante=true
      this.mostrarFavoritos=false
    },
    sendEmail(){
      if(this.$store.state.AppActiveUser.TipoEjecutivoV3!='Inmo' && this.nuevaCotizacion){
        //this.mostrarInfoSolicitante=true
        //this.mostrarFavoritos=false
        this.caso.solicitante=this.email
      }
      let self=this
      var objEmail = {
        to: this.caso.solicitante,
        case: this.id,
        token:this.key
      }
      axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/options-comparative.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        response => {
          if(this.$store.state.AppActiveUser.TipoEjecutivoV3!='Inmo' && this.nuevaCotizacion && response.data.status==201){
            $('#modalTabla').modal('show');
            this.mensajeModal='Correo enviado'
            $('#icono').html('').html('<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16"><path style="color:#079DEF" d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>');
           
            setTimeout(() => $('#modalTabla').modal('hide'), 10000);
            $("#modalTabla").on('hidden.bs.modal', function(){
               self.$router.push('/')              
            });
          }else if(this.$store.state.AppActiveUser.TipoEjecutivoV3!='Inmo' && this.nuevaCotizacion && response.data.status==301){
            $('#modalTabla').modal('show')
            $('#icono').html('').html('<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/><path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/></svg>');
            $("#modalTabla").on('hidden.bs.modal', function(){
               self.$router.push('/')               
            });
            this.mensajeModal='Servicio de correo no disponible'
            setTimeout(() => $('#modalTabla').modal('hide'), 10000);
          }
          this.$vs.notify({
            title:'Se envió correctamente el correo electrónico a',
            text:this.caso.solicitante,
            position:'top-right'
          })
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    prueba(){
      this.$router.push('/').catch(() => {})
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.caso=response.data.objContenido;
            this.caso.solicitante=cotizacion.Solicitante
            this.caso.destino=cotizacion.Destino 
            this.caso.MontoSolicitado=this.caso.MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.caso.ValorInmueble=this.caso.ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            if (this.caso.Destino == 19 || this.caso.Destino == 25 || this.caso.Destino == 22 || this.caso.Destino == 33 || this.caso.Destino == 34) {
              this.caso.SaldoInsoluto=this.caso.SaldoInsoluto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.caso.PagoMensualActual=this.caso.PagoMensualActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.favoritos=cotizacion.Favoritos
            this.favoritos.sort((a,b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
            this.favoritos[0].MejorPagoTotal=true
            this.favoritos.sort((a,b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
            this.favoritos[0].MejorTasa=true
            this.favoritos.sort((a,b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
            this.favoritos[0].MejorCat=true
            this.favoritos.sort((a,b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
            this.favoritos[0].MejorIngresoMinimo=true
            this.favoritos.sort((a,b) => (a.GastosIniciales > b.GastosIniciales) ? 1 : ((b.GastosIniciales > a.GastosIniciales) ? -1 : 0));
            this.favoritos[0].MejorGastosIniciales=true
            this.favoritos.sort((a,b) => (a.Mensualidad > b.Mensualidad) ? 1 : ((b.Mensualidad > a.Mensualidad) ? -1 : 0));
            this.favoritos[0].MejorMensualidad=true

            for (var i = 0; i < this.favoritos.length; i++) {
              this.favoritos[i].GastosIniciales=this.favoritos[i].GastosIniciales.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].Monto=this.favoritos[i].Monto.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].Mensualidad=this.favoritos[i].Mensualidad.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].Cat=this.favoritos[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].IngresoMinimo=this.favoritos[i].IngresoMinimo.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].PagoTotal=this.favoritos[i].PagoTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[i].MensualidadInicial=this.favoritos[i].MensualidadInicial.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    print(){
      var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        setTimeout(function () {
            window.print();
            //window.close();
        }, 200);
      }
      else {
          window.print();
         // window.close();
      }
    }
  }
}
</script>

<style lang="css" scoped>
.logo{
  width: 90%;
}
@media screen and (max-width:820px) {
  .producto{
    display: inline-block;
  }
}
@media print {
 /*td hack {
    display: block;
    page-break-inside: avoid;
  }*/
  body{
    margin: 0;
    padding: 0;
  }
    img{
      display: block;
    }
    .bankName{
      display: block;
    }
  
    .tabla-resumen{
       width: 100%;
       margin:20px 0px;
     }
     .vs-con-table{
       margin:0px;
     }
    
    img.logo{
      width: 70px;
    }

   .btnimprime{
    display: none;
   }
   div#botones{
    display: none;
   }
    .card-resumen{
   padding-top: 55px;
    }

    .producto{
      font-size:3px;
    }
    .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 0px;
}

}


.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
    padding: 0.75rem 2rem;
    color: #fff !important;
    background: #015694 !important;
}

.feather-icon{
  width: 15px;
}
p{
  margin-left: 2%;
  margin-right: 2%;
  color: #ccc;
}
.bankName{
  display: none;
}
</style>
